import React from 'react';
import {Helmet} from 'react-helmet';
import Logo from '../components/Logo';
import Block from '../components/Block';

const PrivacyPage = () => {
    return (
        <>
            <Helmet title="Datenschutz"/>
            <Logo title="Datenschutz"/>

            <Block>
                <h3>Dienstanbieter</h3>
                <p>Deutscher Rollsport und Inline-Verband e.V. / driv.de<br/>
                    Otto-Fleck-Schneise 10a, 60528 Frankfurt am Main</p>
                <dl>
                    <dt>Telefon:</dt>
                    <dd>069 97761737</dd>
                    <dt>E-Mail:</dt>
                    <dd>
                        <a href="mailto:info@driv.de">info@driv.de</a>
                    </dd>
                    <dt>Präsidentin</dt>
                    <dd>Irmelin Otten</dd>
                    <dt>Registergericht Frankfurt</dt>
                    <dd>VR 4925</dd>
                    <dt>Steuernummer</dt>
                    <dd>Finanzamt Frankfurt Main III/IV 045 255 26820</dd>
                </dl>

                <h4>Die Kontaktdaten des für den Datenschutz zuständigen:</h4>
                <dl>
                    <dt>Name:</dt>
                    <dd>Christian Volkmer</dd>
                    <dt>Anschrift:</dt>
                    <dd>Projekt 29 GmbH & Co. KG, Ostengasse 14, 93047 Regensburg</dd>
                    <dt>Telefon:</dt>
                    <dd>0941 298693-19</dd>
                    <dt>Fax:</dt>
                    <dd>0941 298693-16</dd>
                    <dt>E-Mail:</dt>
                    <dd>
                        <a href="mailto:anfrage@projekt29.de">anfrage@projekt29.de</a>
                    </dd>
                    <dt>Web:</dt>
                    <dd>
                        <a href="http://www.projekt29.de/">www.projekt29.de</a>
                    </dd>
                </dl>

                <h3 className="hyphen">Datenschutzerklärung DRIV</h3>
                <p>Für den Deutschen Rollsport und Inlinne Verband (DRIV) hat ein verantwortungsbewusster
                    Umgang mit personenbezogenen Daten hohe Priorität. Wir möchten, dass Nutzerinnen und Nutzer
                    wissen, wann welche Daten durch den DRIV erhoben und verwendet werden. Wir haben technische
                    und organisatorische Maßnahmen getroffen, die sicherstellen, dass die Vorschriften über den
                    Datenschutz sowohl von uns als auch von unseren externen Dienstleistern beachtet werden.</p>

                <h4>Personenbezogene Daten</h4>
                <p>Die Nutzung des Webangebots des DRIV ist grundsätzlich ohne Bekanntgabe personenbezogener
                    Informationen möglich.</p>

                <h4>Was sind personenbezogene Daten?</h4>
                <p>Personenbezogene Daten sind Informationen (z.B. Name, Adresse, Postanschrift, Telefonnummer),
                    die dazu genutzt werden können, die Identität von Nutzerinnen und Nutzern zu erfahren.
                    Informationen, die nicht mit der Identität in Verbindung gebracht werden (z.B. Anzahl der
                    Nutzer einer Internetseite), gehören nicht dazu.</p>

                <h4>Datenerfassung und -speicherung</h4>
                <p>Wenn Sie uns eine Nachricht mittels Kontaktformular oder eine E-Mail senden, so werden Ihre
                    Daten für die Korrespondenz mit Ihnen verwendet. Sollten wir eine Nachricht von Ihnen
                    erhalten, gehen wir davon aus, dass wir zu einer Beantwortung per E-Mail berechtigt sind.
                    Ansonsten müssen Sie uns ausdrücklich auf eine andere Art der Kommunikation hinweisen. Die
                    Kommunikation via E-Mail kann Sicherheitslücken aufweisen. Beispielsweise können E-Mails auf
                    dem Weg an den DRIV von versierten Internet-Nutzern aufgehalten und eingesehen werden. Bei
                    Nutzung eines Kontaktformulars wird dem DRIV neben den Inhalten der Datenfelder auch die
                    IP-Adresse des Absenders übermittelt. Dies erfolgt grundsätzlich auch beim Absenden einer
                    herkömmlichen E-Mail. Eine Verwendung der IP-Adresse findet ausschließlich im Rahmen
                    staatlicher Strafverfolgungs- und Gefahrenabwehrmaßnahmen unter Beachtung der gesetzlichen
                    Voraussetzungen statt.</p>
                <p>Die Aufbewahrung von Bürgeranfragen in elektronischer Form erfolgt - wie auch in Papierform -
                    gemäß den für die Aufbewahrung von Schriftgut geltenden Fristen der Registraturrichtlinien.
                    Bei der Bestellung von Informationsmaterial oder Broschüren, wird nach Namen und anderen
                    persönlichen Informationen gefragt. Es unterliegt der freien Entscheidung der Nutzerinnen
                    und Nutzer, ob sie diese Daten mitteilen möchten.</p>
                <p>Bei jedem Zugriff auf die hiesigen Server werden darüber hinaus Daten für statistische und
                    Sicherungszwecke gespeichert. Dabei werden lediglich für eine begrenzte Zeit die IP-Adresse
                    des Internet Service Providers, das Datum und die Uhrzeit des Zugriffs sowie die konkrete
                    Zieladresse erfasst. Diese Daten werden ausschließlich zur Verbesserung des
                    Internetangebotes genutzt und nicht auf die Nutzerinnen und Nutzer zurückführbar
                    ausgewertet.</p>

                <h4>Weitergabe personenbezogener Daten an Dritte</h4>
                <p>Daten, die beim Zugriff auf das Internetangebot des DRIV erfasst worden sind, werden nur an
                    Dritte übermittelt, wenn der DRIV zu einer Übermittlung gesetzlich oder durch
                    Gerichtsentscheidung verpflichtet ist. Eine Weitergabe zu anderen nichtkommerziellen oder zu
                    kommerziellen Zwecken erfolgt nicht.</p>

                <h4>Einsatz von Cookies</h4>
                <p>Wir verwenden auf unserer Webseite Cookies. Diese kleinen Textdateien werden von unserem
                    Server aus auf Ihrem PC gespeichert. Sie unterstützen die Darstellung unserer Webseite und
                    helfen Ihnen, sich auf unserer Webseite zu bewegen. Cookies erfassen Daten zu Ihrer
                    IP-Adresse, zu Ihrem Browser, Ihrem Betriebssystem und Ihrer Internetverbindung. Diese
                    Informationen verbinden wir nicht mit personenbezogenen Daten und geben sie nicht an Dritte
                    weiter. Keinesfalls werden Cookies von uns dazu benutzt, Schad- oder Spionageprogramme auf
                    Ihren Rechner zu bringen. Sie können unsere Webseite auch ohne den Einsatz von Cookies
                    nutzen, wodurch möglicherweise einige Darstellungen und Funktionen unseres Angebots nur
                    eingeschränkt arbeiten.<br/>
                    Die meisten Browser sind so eingestellt, dass sie Cookies automatisch akzeptieren. Das
                    Speichern von Cookies kann jedoch deaktiviert oder der Browser so eingestellt werden, dass
                    Cookies nur für die Dauer der jeweiligen Verbindung zum Internet gespeichert werden.</p>

                <h4>Minderjährigenschutz</h4>
                <p>Personen unter 18 Jahren sollten ohne Zustimmung der Eltern oder Erziehungsberechtigten keine
                    personenbezogenen Daten an uns übermitteln. Wir fordern personenbezogene Daten von Kindern
                    und Jugendlichen nur an soweit sie im Zusammenhang mit deren sportlichen Betätigungen
                    stehen, wie z.B. Meldungen zu internationalen Wettbewerbungen, Meldungen an die NADA im
                    Zusammenhang mit dem deutschen Antidopingsystem oder an den DOSB im Rahmen der
                    Registrierungen in dessen Trainer-Lizenzmanagementsystem oder in der DaLID-Datenbank. Wir
                    geben diese Daten auch nicht an Dritte weiter.</p>

                <h4>Datenschutzerklärung für die sozialen Medien</h4>
                <p>Der DRIV nimmt die Diskussion um den Datenschutz in sozialen Netzwerken sehr ernst. Es ist
                    gegenwärtig rechtlich nicht abschließend geklärt, ob und inwieweit alle Netzwerke ihre
                    Dienste im Einklang mit europäischen datenschutzrechtlichen Bestimmungen anbieten. Wir
                    machen daher ausdrücklich darauf aufmerksam, dass die auch seitens des DRIV genutzten
                    Dienste Facebook, Twitter und YouTube die Daten ihrer Nutzerinnen und Nutzer (z.B.
                    persönliche Informationen, IP-Adresse etc.) entsprechend ihrer Datenverwendungsrichtlinien
                    abspeichern und für geschäftliche Zwecke nutzen. Der DRIV hat keinen Einfluss auf die
                    Datenerhebung und deren weitere Verwendung durch die sozialen Netzwerke. So bestehen keine
                    Erkenntnisse darüber, in welchem Umfang, an welchem Ort und für welche Dauer die Daten
                    gespeichert werden, inwieweit die Netzwerke bestehenden Löschpflichten nachkommen, welche
                    Auswertungen und Verknüpfungen mit den Daten vorgenommen werden und an wen die Daten
                    weitergegeben werden.</p>

                <h4>E-Mail Kontaktformular</h4>
                <p>Wir möchten Sie darauf hinweisen, dass Ihre Nachricht zwar verschlüsselt an unseren
                    Mailserver übertragen wird, die Zustellung jedoch unverschlüsselt über das Internet erfolgt.
                    Bitte fügen Sie daher keine vertraulichen Informationen in das Kontaktformular oder in eine
                    unverschlüsselte E-Mail ein. Die im Kontaktformular erhobenen Angaben zu Vorname, Name,
                    Organisation, E-Mail, Telefonnummer und Nachricht werden von uns nur zum Zweck der
                    Beantwortung Ihrer Anfrage bei uns gespeichert. Eine Weitergabe an Dritte erfolgt nicht.
                    Nach Abschluss der Bearbeitung werden die Angaben gelöscht. Lediglich im Rahmen der
                    gesetzlich vorgeschriebenen Archivierung des Mailverkehrs werden Daten darüber hinaus für
                    max. 10 Jahre aufbewahrt.</p>

                <h4>Server Logfiles</h4>
                <p>Bei den Server Logfiles handelt es sich um anonymisierte Daten, die bei Ihrem Zugriff auf
                    unsere Webseite erfasst werden. Diese Informationen ermöglichen keine Rückschlüsse auf Sie
                    persönlich, sind aber aus technischen Gründen für die Auslieferung und Darstellung unserer
                    Inhalte unverzichtbar. Weiterhin dienen Sie unserer Statistik und der ständigen Optimierung
                    unserer Inhalte. Typische Logfiles sind das Datum und die Zeit des Zugriffs, die Datenmenge,
                    der für den Zugriff benutzte Browser und seine Version, das eingesetzte Betriebssystem, der
                    Domainname des von Ihnen beauftragten Providers, die Seite, von der Sie zu unserem Angebot
                    gekommen sind (Referrer-URL) und Ihre IP-Adresse. Logfiles ermöglichen außerdem eine genaue
                    Prüfung bei Verdacht auf eine rechtswidrige Nutzung unserer Webseite.</p>

                <h4>SSL-Verschlüsselung</h4>
                <p>Unsere Webseite bedient sich einer SSL-Verschlüsselung, wenn es um die Übermittlung
                    vertraulicher oder persönlicher Inhalte unserer Nutzer geht. Diese Verschlüsslung wird zum
                    Beispiel bei der Abwicklung des Zahlungsverkehrs sowie bei Anfragen aktiviert, die Sie an
                    uns über unsere Webseite stellen. Achten Sie bitte darauf, dass die SSL-Verschlüsselung bei
                    entsprechenden Aktivitäten von Ihrer Seite her aktiviert ist. Der Einsatz der
                    Verschlüsselung ist leicht zu erkennen: Die Anzeige in Ihrer Browserzeile wechselt von
                    „http://“ zu „https://“. Über SSL verschlüsselte Daten sind nicht von Dritten lesbar.
                    Übermitteln Sie Ihre vertraulichen Informationen nur bei aktivierter SSL-Verschlüsselung und
                    wenden Sie sich im Zweifel an uns.</p>

                <h4>Änderungen der Datenschutzerklärung</h4>
                <p>Im Zuge der (technologischen) Weiterentwicklung unserer Webseite können Änderungen dieser
                    Datenschutzerklärung erforderlich werden. Daher wird empfohlen, sich diese
                    Datenschutzerklärung ab und zu erneut durchzulesen.</p>

                <h4>Google Maps Plugin</h4>
                <p>Wir setzen auf unserer Webseite ein Plugin des Internetdienstes Google Maps ein. Betreiber
                    von Google Maps ist Google Inc., ansässig in den USA, CA 94043, 1600 Amphitheatre Parkway,
                    Mountain View.</p>
                <p>Indem Sie Google Maps auf unserer Webseite nutzen, werden Informationen über die Nutzung
                    dieser Webseite und Ihre IP-Adresse an einen Google-Server in den USA übermittelt und auch
                    auf diesem Server gespeichert. Wir haben keine Kenntnis über den genauen Inhalt der
                    übermittelten Daten, noch über ihre Nutzung durch Google. Das Unternehmen verneint in diesem
                    Kontext die Verbindung der Daten mit Informationen aus anderen Google-Diensten und die
                    Erfassung personenbezogener Daten. Allerdings kann Google die Informationen an Dritte
                    übermitteln.</p>
                <p>Wenn Sie Javascript in Ihrem Browser deaktivieren, verhindern Sie die Ausführung von Google
                    Maps. Sie können dann aber auch keine Kartenanzeige auf unserer Webseite nutzen.</p>
                <p>Mit der Nutzung unserer Webseite erklären Sie Ihr Einverständnis mit der beschriebenen
                    Erfassung und Verarbeitung der Informationen durch Google Inc..</p>
                <p>Näheres zu den Datenschutzbestimmungen und Nutzungsbedingungen für Google Maps erfahren Sie
                    hier:<br/>
                    <a href="https://www.google.com/intl/de_de/help/terms_maps.html" target="_blank"
                       rel="noreferrer"> https://www.google.com/intl/de_de/help/terms_maps.html</a>.</p>
            </Block>
        </>
    )
};

export default PrivacyPage;
